// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import "./interceptors/axios";
// import awsRum from "./rum";
// // import { LoaderProvider } from "./common/loaderContext";
// // import Loader from "./components/loader";

// import { Provider } from 'react-redux';
// import store from './store/index';
// console.log("AWS RUM initialized:", awsRum);



// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import './common/loader'
import "./interceptors/axios";
import awsRum from "./rum";

console.log("awsRum", awsRum);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();