import React, { useState, useEffect } from "react";
import { Form, Button, ListGroup, Row, Col } from "react-bootstrap";
import "./style.css";
import {
  teamUsers,
  teamForms,
  teamLocationParameter,
  teamVariable,
} from "../../hooks/teamInfo";
import Select from "react-select";
import { RxCrossCircled } from "react-icons/rx";
import SelectFormGroup from "../../common/select-field";
import { createAlert, updateAlert } from "../../hooks/alertSystem";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import Loader from "../../common/loader";
import { useDispatch } from "react-redux";

const AlertManagement = ({
  orgInfo,
  editAlert,
  onAlertManagement,
  updateMessage,
}) => {
  const defaultAlertData = {
    team: "",
    alertName: "",
    description: "",
    alertType: "",
    assignedUsers: [],
    notificationPreference: [],
    org: orgInfo?.orgId,
    frequency: "",
    repeatIfUnsolved: "",
  };
  const [alertData, setAlertData] = useState(defaultAlertData);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [columns, setColumns] = useState([]);
  const [locations, setLocations] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [forms, setForms] = useState([]);
  const [errors, setErrors] = useState({});
  const [locationParameterPairs, setLocationParameterPairs] = useState([]);
  const [initialAlertData, setInitialAlertData] = useState(null);
  const [isAlertChange, setIsAlertChanged] = useState(false);
  const [frequencyType, setFrequencyType] = useState([]);
  const [repeatType, setRepeatType] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dataTypes, setDataTypes] = useState([
    { label: "Lab Test", value: "lab_test" },
  ]);

  const frequencyOptions = {
    form: [{ label: "Daily", value: "daily" }],
    threshold: {
      mqtt: [{ label: "Every 1 minute", value: "1_minute" }],
      lab_test: [{ label: "On Change", value: "on_change" }],
    },
    lab_test: [{ label: "Daily", value: "daily" }],
    mqtt: [{ label: "Every 1 minute", value: "1_minute" }],
  };

  const repeatOptions = {
    form: [{ label: "No Repeat", value: "no_repeat" }],
    threshold: {
      mqtt: [
        { label: "1 hour", value: "1_hour" },
        { label: "4 hours", value: "4_hours" },
        { label: "8 hours", value: "8_hours" },
        { label: "24 hours", value: "24_hours" },
        { label: "No Repeat", value: "no_repeat" },
      ],
      lab_test: [{ label: "No Repeat", value: "no_repeat" }],
    },
    lab_test: [{ label: "No Repeat", value: "no_repeat" }],
    mqtt: [
      { label: "1 hour", value: "1_hour" },
      { label: "4 hours", value: "4_hours" },
      { label: "8 hours", value: "8_hours" },
      { label: "24 hours", value: "24_hours" },
      { label: "No Repeat", value: "no_repeat" },
    ],
  };

  const [alertType, setAlertType] = useState([]);
  const dispatch = useDispatch();
  // const dataTypes = [
  //   // { label: "MQTT", value: "mqtt" },
  //   { label: "Lab Test", value: "lab_test" },
  // ];

  const thresholdType = [
    { label: "Upper Bound", value: "upper_bound" },
    { label: "Lower Bound", value: "lower_bound" },
    // { label: "Within Range", value: "within_range" },
    // { label: "Out of Range", value: "out_of_range" },
  ];

   const mqttTeams = [4, 5, 6]; //prodMqtt Teams
 //  const mqttTeams = [60, 37, 48];

  useEffect(() => {
    if (initialAlertData) {
      setIsAlertChanged(
        JSON.stringify(alertData) !== JSON.stringify(initialAlertData)
      );
    }
  }, [alertData, initialAlertData]);

  useEffect(() => {
    setFrequencyType(frequencyOptions[alertData.alertType]);
    setRepeatType(repeatOptions[alertData.alertType]);
    if (!editAlert) {
      if (alertData.alertType === "form") {
        setAlertData((prevData) => ({
          ...prevData,
          forms: [],
          completionTime: "",
        }));
      } else if (alertData.alertType === "lab_test") {
        setAlertData((prevData) => ({
          ...prevData,
          locationParameterPairs: [],
          completionTime: "",
        }));
      } else if (alertData.alertType === "threshold") {
        setAlertData((prevData) => ({
          ...prevData,
          dataType: "",
          variable: "",
          thresholdType: "",
          thresholdValue: "",
        }));
      }
    }
  }, [alertData.alertType]);

  const formatTime = (time) => {
    if (time) {
      const [hours, minutes] = time.split(":");
      return `${hours}:${minutes}`;
    }
    return null;
  };

  useEffect(() => {
    if (!orgInfo) return;
    const teams = [];
    orgInfo.teams.forEach((team) => {
      if (team.teamName !== "Master Data Management") {
        teams.push({
          teamId: team.teamId,
          teamName: team.teamName,
        });
      }
    });
    setTeams(teams);
    // If editing, populate the form with existing data
    if (editAlert) {
      let updateFields = {
        team: editAlert.team,
        org: editAlert.org,
        alertName: editAlert.alert_name,
        description: editAlert.description,
        alertType: editAlert.alert_type,
        notificationPreference: editAlert.notification_preferences,
        assignedUsers: editAlert.assigned_users.map((user) => user.userId),
        frequency: editAlert.frequency,
        repeatIfUnsolved: editAlert.repeat_if_unsolved,
      };
      if (editAlert.alert_type === "form") {
        updateFields = {
          ...updateFields,
          completionTime: formatTime(editAlert.completion_time),
          forms: editAlert.forms.map((form) => form.formId),
        };
      }
      if (editAlert.alert_type === "lab_test") {
        updateFields = {
          ...updateFields,
          completionTime: formatTime(editAlert.completion_time),
          locationParameterPairs: editAlert.select_location_parameter,
        };
      }
      if (editAlert.alert_type === "threshold") {
        updateFields = {
          ...updateFields,
          dataType: editAlert.select_datatype,
          variable: editAlert.select_variable,
          thresholdType: editAlert.threshold_type,
          thresholdValue: editAlert.threshold_value,
          minValue: editAlert.minimum_value,
          maxValue: editAlert.maximum_value,
        };
      }

      setAlertData(updateFields);
      setInitialAlertData(updateFields);
      setAssignedUsers(
        editAlert.assigned_users.map((user) => ({
          userId: user.userId,
          userName: user.userEmail,
        }))
      );
      setSelectedForms(editAlert.forms || []);
      setLocationParameterPairs(editAlert.select_location_parameter || []);
    }
  }, [orgInfo, editAlert]);

  useEffect(() => {
    if (alertData.dataType === "lab_test") {
      dispatch(showLoader());
      teamLocationParameter(alertData.team)
        .then((response) => {
          const locationParameterPairs = response.data.location.flatMap(
            (location) =>
              response.data.parameter.map((parameter) => ({
                value: `${location} : ${parameter}`,
                label: `${location} : ${parameter}`,
              }))
          );
          setColumns(locationParameterPairs);
          dispatch(hideLoader());
        })
        .catch((error) => {
          console.error("Error fetching forms:", error);
          dispatch(hideLoader());
        });
    }
    if (alertData.dataType === "mqtt") {
      dispatch(showLoader());
      teamVariable(alertData.team)
        .then((response) => {
          const variableOption = response.data.map((lc) => ({
            value: lc,
            label: lc,
          }));
          setColumns(variableOption);
          dispatch(hideLoader());
        })
        .catch((error) => {
          console.error("Error fetching forms:", error);
          dispatch(hideLoader());
        });
    }
  }, [alertData.team, alertData.alertType, alertData.dataType, dispatch]);

  useEffect(() => {
    if (alertData.alertType === "threshold") {
      if (mqttTeams.includes(alertData.team)) {
        setDataTypes([
          { label: "MQTT", value: "mqtt" },
          { label: "Lab Test", value: "lab_test" },
        ]);
      }
    }
    if (alertData.alertType === "lab_test") {
      dispatch(showLoader());
      teamLocationParameter(alertData.team)
        .then((response) => {
          const locationOptions = response.data.location.map((lc) => ({
            value: lc,
            label: lc,
          }));
          setLocations(locationOptions);
          const parameterOptions = response.data.parameter.map((pr) => ({
            value: pr,
            label: pr,
          }));
          setParameters(parameterOptions);
          dispatch(hideLoader());
        })
        .catch((error) => {
          console.error("Error fetching forms:", error);
          dispatch(hideLoader());
        });
    }

    if (alertData.alertType === "form") {
      dispatch(showLoader());
      teamForms(alertData.team)
        .then((response) => {
          const formOptions = response.data.map((form) => ({
            value: form.formId,
            label: form.formName,
          }));
          setForms(formOptions);
          dispatch(hideLoader());
        })
        .catch((error) => {
          console.error("Error fetching forms:", error);
          dispatch(hideLoader());
        });
    }
  }, [alertData.team, alertData.alertType, dispatch]);

  useEffect(() => {
    if (alertData.team) {
      setUsers([]);
      if (!editAlert) {
        setAssignedUsers([]);
      }
      dispatch(showLoader());
      teamUsers(alertData.team)
        .then((response) => {
          const userOptions = response.data.map((user) => ({
            value: user.userId,
            label: user.userEmail,
          }));
          setUsers(userOptions);
          dispatch(hideLoader());
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
          dispatch(hideLoader());
        });
    }
  }, [alertData.team, dispatch]);

  const checkLabTestFormInTeam = (teamId, teams) => {
    const team = teams.find((team) => Number(team.teamId) === Number(teamId));
    if (team && team.forms) {
      return team.forms.some((form) => form.formName === "Lab Test");
    }
    return false;
  };

  useEffect(() => {
    if (alertData.team) {
      const alertTypesSet = new Set();

      const checkLabTestForm = checkLabTestFormInTeam(
        alertData.team,
        orgInfo.teams,
        true
      );
      if (mqttTeams.includes(alertData.team)) {
        alertTypesSet.add(JSON.stringify({ label: "MQTT", value: "mqtt" }));
        alertTypesSet.add(
          JSON.stringify({ label: "Threshold", value: "threshold" })
        );
      }
      if (checkLabTestForm) {
        alertTypesSet.add(
          JSON.stringify({ label: "Lab Test", value: "lab_test" })
        );
        alertTypesSet.add(
          JSON.stringify({ label: "Threshold", value: "threshold" })
        );
      }
      // const checkForms = checkLabTestFormInTeam(alertData.team, orgInfo.teams);
      if (alertData.team) {
        alertTypesSet.add(JSON.stringify({ label: "Form", value: "form" }));
      }

      const alertTypes = Array.from(alertTypesSet).map((item) =>
        JSON.parse(item)
      );
      setAlertType(alertTypes);
    }
  }, [alertData.team]);
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "team") {
  //     setAlertData({
  //       ...alertData,
  //       [name]: value,
  //       assignedUsers: [],
  //       forms: [],
  //       locationParameterPairs: [],
  //     });
  //     setAssignedUsers([]);
  //     setSelectedForms([]);
  //     setLocationParameterPairs([]);
  //     setAlertType([]);
  //     if (value && mqttTeams.includes(value)) {
  //       console.log("inside value----")
  //       setAlertType([
  //         { label: "MQTT", value: "mqtt" },
  //         { label: "Threshold", value: "threshold" },
  //       ]);
  //     }
  //   }
  //   setAlertData({ ...alertData, [name]: value });
  //   if (errors[name]) {
  //     setErrors((prevErrors) => {
  //       const newErrors = { ...prevErrors };
  //       delete newErrors[name];
  //       return newErrors;
  //     });
  //   }
  // };


  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "team" || name === "alertType" || name === "dataType") {
      setAlertData({
        ...alertData,
        [name]: value,
        assignedUsers: [],
        forms: [],
        locationParameterPairs: [],
        completionTime: "",
        notificationPreference: [],
        frequency: "",
        repeatIfUnsolved: "",
        variable: "",
        thresholdType: "",
      });
      setAssignedUsers([]);
      setSelectedForms([]);
      setLocationParameterPairs([]);
        // Update frequency and repeatIfUnsolved based on alert type
     
    } else {
      setAlertData({ ...alertData, [name]: value });
    }

    if (name === "alertType" || name === "dataType") {
      let frequency = [];
      let repeat = [];
      if (value === "form") {
        frequency = frequencyOptions.form;
        repeat = repeatOptions.form;
      } else if (alertData.alertType === "threshold") {
        const dataType = value;
        frequency = frequencyOptions.threshold[dataType] || [];
        repeat = repeatOptions.threshold[dataType] || [];
      } else if (value === "lab_test") {
        frequency = frequencyOptions.lab_test;
        repeat = repeatOptions.lab_test;
      } else if (value === "mqtt") {
        frequency = frequencyOptions.mqtt;
        repeat = repeatOptions.mqtt;
      }

      setFrequencyType(frequency);
      setRepeatType(repeat);

      setAlertData((prevData) => ({
        ...prevData,
        frequency: frequency.length === 1 ? frequency[0]?.value : "",
        repeatIfUnsolved: repeat.length === 1 ? repeat[0]?.value : "",
      }));
    }
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  const handleSave = () => {
    const alertType = alertData.alertType;
    const newErrors = {};
    if (!alertData.team) newErrors.team = "Team is required";
    if (!alertData.alertName) newErrors.alertName = "Alert Name is required";
    if (!alertData.description)
      newErrors.description = "Description is required";
    if (!alertData.alertType) newErrors.alertType = "Alert Type is required";
    if (
      (!alertData.assignedUsers && alertData.assignedUsers.length === 0) ||
      assignedUsers.length === 0
    ) {
      if (users && users.length === 0 && alertData.team) {
        newErrors.assignedUsers =
          "Please select a different team or ensure the team has an email.";
      } else {
        newErrors.assignedUsers = "Email is required";
      }
    }
    if (!alertData.notificationPreference.length)
      newErrors.notificationPreference = "Notification Preferences is required";
    if (!alertData.frequency || !alertData.alertType)
      newErrors.frequency = "Frequency is required";
    if (!alertData.repeatIfUnsolved || !alertData.alertType)
      newErrors.repeatIfUnsolved = "Repeat if Unsolved is required";

    switch (alertType) {
      case "threshold":
        if (!alertData.dataType) newErrors.dataType = "Data Type is required";
        // if (!alertData.table) newErrors.table = "Table is required";
        if (!alertData.variable) newErrors.variable = "Variable is required";
        if (!alertData.thresholdType)
          newErrors.thresholdType = "Threshold Type is required";
        if (
          alertData.thresholdType === "upper_bound" ||
          alertData.thresholdType === "lower_bound"
        ) {
          if (!alertData.thresholdValue)
            newErrors.thresholdValue = "Threshold Value is required";
        } else {
          if (!alertData.minValue)
            newErrors.minValue = "Minimum Value is required";
          if (!alertData.maxValue)
            newErrors.maxValue = "Maximum Value is required";
        }
        break;
      case "form":
        if (
          (!alertData.forms && alertData.forms.length === 0) ||
          selectedForms.length === 0
        ) {
          if (forms && forms.length === 0) {
            newErrors.forms =
              "Please select a different team or ensure the team has an form.";
          } else {
            newErrors.forms = "Form is required";
          }
        }
        if (!alertData.completionTime)
          newErrors.completionTime = "Completion Time is required";
        break;
      case "lab_test":
        if (!alertData.frequency) newErrors.frequency = "Frequency is required";
        if (
          !alertData.locationParameterPairs ||
          alertData.locationParameterPairs.length === 0
        ) {
          newErrors.locationParameterPairs =
            "At least one location-parameter pair is required";
        }
        if (!alertData.completionTime)
          newErrors.completionTime = "Completion Time is required";
        break;
      default:
        break;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (editAlert) {
      updateAlertData(alertData, editAlert.id);
    } else {
      createAlertData(alertData);
    }
  };

  const createAlertData = (alertData) => {
    alertData.org = orgInfo?.orgId;
    dispatch(showLoader());
    createAlert(alertData)
      .then((response) => {
        if (response && response.success) {
          dispatch(hideLoader());
          updateMessage("success", response.message);
          onAlertManagement();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        updateMessage(
          "error",
          error.message || "Error occurred while creating alert"
        );
      });
  };

  const updateAlertData = (alertData, alertId) => {
    dispatch(showLoader());
    updateAlert(alertData, alertId)
      .then((response) => {
        if (response && response.success) {
          dispatch(hideLoader());
          updateMessage("success", response.message);
          onAlertManagement();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        updateMessage(
          "error",
          error.message || "Error occurred while creating alert"
        );
      });
  };
  const handleClear = () => {
    setAlertData({
      team: "",
      alertName: "",
      description: "",
      alertType: "",
      frequency: "",
      repeatIfUnsolved: "",
      assignedUsers: "",
      notificationPreference: [],
      forms: [],
      completionTime: "",
    });
    setAssignedUsers([]);
    setSelectedForms([]);
    setLocationParameterPairs([]);
    setErrors({});
  };

  const handleAssignUser = (selectedOption) => {
    const user = selectedOption ? selectedOption.value : "";
    if (user && !assignedUsers.some((u) => u.userId === user)) {
      const updatedAssignedUsers = [
        ...assignedUsers,
        { userId: user, userName: selectedOption.label },
      ];
      setAssignedUsers(updatedAssignedUsers);

      setAlertData({
        ...alertData,
        assignedUsers: updatedAssignedUsers.map((user) => user.userId), // Update assignedUsers with user IDs
      });

      if (errors.assignedUsers) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.assignedUsers;
          return newErrors;
        });
      }
    }
  };

  useEffect(() => {
    if (!alertData.alertType) return;

    let frequency = [];
    let repeat = [];

    if (alertData.alertType === "form") {
      frequency = frequencyOptions.form;
      repeat = repeatOptions.form;
    } else if (alertData.alertType === "threshold") {
      const dataType = alertData.dataType;
      frequency = frequencyOptions.threshold[dataType] || [];
      repeat = repeatOptions.threshold[dataType] || [];
    } else if (alertData.alertType === "lab_test") {
      frequency = frequencyOptions.lab_test;
      repeat = repeatOptions.lab_test;
    } else if (alertData.alertType === "mqtt") {
      frequency = frequencyOptions.threshold.mqtt;
      repeat = repeatOptions.mqtt;
    }

    setFrequencyType(frequency);
    setRepeatType(repeat);
    // Set default values to the first option
    if (!editAlert) {
      setAlertData((prevData) => ({
        ...prevData,
        frequency:
          frequency && frequency.length === 1 ? frequency[0]?.value : "",
        repeatIfUnsolved: repeat && repeat.length === 1 ? repeat[0]?.value : "",
      }));
    }
  }, [alertData.alertType, alertData.dataType, editAlert]);

  const handleFormChange = (selectedOption) => {
    const formId = selectedOption ? selectedOption.value : "";
    if (formId && !selectedForms.some((u) => u.formId === formId)) {
      const updatedSelectedForms = [
        ...selectedForms,
        { formId: formId, formName: selectedOption.label },
      ];
      setSelectedForms(updatedSelectedForms);

      setAlertData({
        ...alertData,
        forms: updatedSelectedForms.map((form) => form.formId), // Update forms with form IDs
      });

      if (errors.forms) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.forms;
          return newErrors;
        });
      }
    }
  };

  const handleLocationChange = (selectedOption) => {
    const location = selectedOption ? selectedOption.value : "";
    setAlertData({ ...alertData, location });
    setSelectedLocation(selectedOption ? selectedOption.value : null);
  };
  const handleParameterChange = (selectedOption) => {
    const parameter = selectedOption ? selectedOption.value : "";
    setAlertData({ ...alertData, parameter });

    if (alertData.location && parameter) {
      const newPair = { location: alertData.location, parameter };
      const updatedPairs = [...locationParameterPairs, newPair];
      setLocationParameterPairs(updatedPairs);
      setAlertData({
        ...alertData,
        location: "",
        parameter: "",
        locationParameterPairs: updatedPairs,
      });
    }
  };

  const handleNotification = (event) => {
    const { checked, value } = event.target;
    setAlertData((prevData) => {
      const newPreferences = checked
        ? [...prevData.notificationPreference, value]
        : prevData.notificationPreference.filter((pref) => pref !== value);
      return {
        ...prevData,
        notificationPreference: newPreferences,
      };
    });

    if (errors.notificationPreference) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.notificationPreference;
        return newErrors;
      });
    }
  };

  const handleRemoveAssignedUser = (userId) => {
    const updatedAssignedUsers = assignedUsers.filter(
      (user) => user.userId !== userId
    );
    setAssignedUsers(updatedAssignedUsers);
    setAlertData((prevData) => ({
      ...prevData,
      assignedUsers: updatedAssignedUsers.map((user) => user.userId),
    }));
  };

  const handleRemoveSelectedForm = (formId) => {
    const updatedSelectedForms = selectedForms.filter(
      (form) => form.formId !== formId
    );
    setSelectedForms(updatedSelectedForms);
    setAlertData((prevData) => ({
      ...prevData,
      forms: updatedSelectedForms.map((form) => form.formId),
    }));
  };

  const handleRemoveSelectedLocation = (index) => {
    const updatedSelectedLocation = locationParameterPairs.filter(
      (_, i) => i !== index
    );
    setLocationParameterPairs(updatedSelectedLocation);
    setAlertData((prevData) => ({
      ...prevData,
      locationParameterPairs: updatedSelectedLocation,
    }));
  };

  const renderAdditionalFields = () => {
    switch (alertData.alertType) {
      case "threshold":
        return (
          <>
            <SelectFormGroup
              controlId="dataType"
              label="Select Data Type"
              options={dataTypes}
              value={alertData.dataType}
              onChange={handleChange}
              error={errors.dataType}
              placeholder="Select Data Type"
              isInvalid={!!errors.dataType}
            />
            <SelectFormGroup
              controlId="variable"
              label="Select Variable"
              options={columns}
              value={alertData.variable}
              onChange={handleChange}
              error={errors.variable}
              placeholder="Select Variable"
              isInvalid={!!errors.variable}
            />
            <SelectFormGroup
              controlId="thresholdType"
              label="Threshold Type"
              options={thresholdType}
              value={alertData.thresholdType}
              onChange={handleChange}
              error={errors.thresholdType}
              placeholder="Select Threshold Type"
              isInvalid={!!errors.thresholdType}
            />
            {alertData.thresholdType &&
              (alertData.thresholdType === "upper_bound" ||
              alertData.thresholdType === "lower_bound" ? (
                <Form.Group
                  as={Row}
                  controlId="thresholdValue"
                  className="mb-3"
                >
                  <Form.Label column sm={2}>
                    Threshold Value <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="thresholdValue"
                      value={alertData.thresholdValue || ""}
                      onChange={(e) => {
                        const input = e.target.value;
                        const regex = /^-?\d{0,10}(\.\d{0,2})?$/;
                        const invalidPatterns = /--|\.\.|-\d+-/;
                        if (
                          (regex.test(input) && !invalidPatterns.test(input)) ||
                          input === ""
                        ) {
                          handleChange(e); // Only update valid values
                        }
                      }}
                      placeholder="Enter Threshold Value"
                      isInvalid={!!errors.thresholdValue}
                      onKeyDown={(e) => {
                        if (["e", "E", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.thresholdValue}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : (
                <>
                  <Form.Group as={Row} controlId="minValue" className="mb-3">
                    <Form.Label column sm={2}>
                      Minimum Value <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="minValue"
                        value={alertData.minValue}
                        onChange={handleChange}
                        placeholder="Enter Minimum Value"
                        isInvalid={!!errors.minValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.minValue}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="maxValue" className="mb-3">
                    <Form.Label column sm={2}>
                      Maximum Value <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="maxValue"
                        value={alertData.maxValue}
                        onChange={handleChange}
                        placeholder="Enter Maximum Value"
                        isInvalid={!!errors.maxValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.maxValue}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              ))}
          </>
        );
      case "form":
        return (
          <>
            <Form.Group as={Row} controlId="forms" className="mb-3">
              <Form.Label column sm={2}>
                Select Form <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Select
                  name="forms"
                  value={null}
                  onChange={handleFormChange}
                  options={forms}
                  placeholder="Select Form"
                  isInvalid={!!errors.forms}
                  isOptionDisabled={(option) =>
                    selectedForms.some((f) => f.formId === option.value)
                  }
                  classNamePrefix={
                    !!errors.forms ? "invalid" : "select-dropdown"
                  }
                  menuPlacement="auto"
                />
                {errors.forms && (
                  <div className="invalid-feedback d-block">{errors.forms}</div>
                )}
                <div className="selected-forms-box mt-3">
                  <ListGroup>
                    <ListGroup.Item className="selected-forms-label">
                      <div className="form-title">Assigned Forms</div>

                      <div className="form-items-name">
                        {selectedForms.map((form, index) => (
                          <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {form?.formName}
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() =>
                                handleRemoveSelectedForm(form.formId)
                              }
                            >
                              <RxCrossCircled />
                            </Button>
                          </ListGroup.Item>
                        ))}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="completionTime" className="mb-3">
              <Form.Label column sm={2}>
                Completion Time <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="time"
                  name="completionTime"
                  value={alertData.completionTime}
                  onChange={handleChange}
                  isInvalid={!!errors.completionTime}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.completionTime}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        );
      case "lab_test":
        return (
          <>
            <Form.Group
              as={Row}
              controlId="locationAndParameter"
              className="mb-3"
            >
              <Form.Label column sm={2}>
                Select Location - Parameter{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Group as={Row}>
                  <Col sm={6}>
                    <Select
                      name="location"
                      options={locations}
                      value={
                        locations.find(
                          (option) => option.value === alertData.location
                        ) || null
                      }
                      onChange={handleLocationChange}
                      placeholder="Select Location"
                      isClearable
                      menuPlacement="auto"
                      className="location-dropdown"
                      classNamePrefix={
                        errors.locationParameterPairs
                          ? "invalid"
                          : "select-dropdown"
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <Select
                      name="parameter"
                      options={parameters}
                      value={null}
                      onChange={handleParameterChange}
                      placeholder="Select Parameter"
                      isClearable
                      isOptionDisabled={(option) =>
                        locationParameterPairs.some(
                          (pair) =>
                            pair.location === selectedLocation &&
                            pair.parameter === option.value
                        )
                      }
                      classNamePrefix={
                        errors.locationParameterPairs
                          ? "invalid"
                          : "select-dropdown"
                      }
                      menuPlacement="auto"
                    />
                  </Col>
                </Form.Group>
                {errors.locationParameterPairs && (
                  <div className="invalid-feedback d-block">
                    {errors.locationParameterPairs}
                  </div>
                )}
                <div className="location-parameter-box mt-3">
                  <ListGroup>
                    <ListGroup.Item className="location-parameter-label">
                      <div className="location-parameter-title">
                        Assigned Location - Parameter
                      </div>
                      <div className="location-parameter">
                        {locationParameterPairs.map((pair, index) => (
                          <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div key={index}>
                              {pair.location} : {pair.parameter}
                            </div>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() =>
                                handleRemoveSelectedLocation(index)
                              }
                            >
                              <RxCrossCircled />
                            </Button>
                          </ListGroup.Item>
                        ))}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="completionTime" className="mb-3">
              <Form.Label column sm={2}>
                Completion Time <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="time"
                  name="completionTime"
                  value={alertData.completionTime}
                  onChange={handleChange}
                  placeholder="Enter Completion Time"
                  isInvalid={!!errors.completionTime}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.completionTime}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="new-aler-outer">
      <Loader />
      <h2>
        <i onClick={() => onAlertManagement()}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="square"
              strokeMiterlimit="10"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>
        </i>{" "}
        {editAlert ? "Edit Alert Details" : "Create New Alert"}
      </h2>
      <Form>
        <SelectFormGroup
          controlId="team"
          label="Team"
          options={teams.map((team) => ({
            value: team.teamId,
            label: team.teamName,
          }))}
          value={alertData.team}
          onChange={handleChange}
          error={errors.team}
          placeholder="Select Team"
          isInvalid={!!errors.team}
        />
        <Form.Group as={Row} controlId="alertName" className="mb-3">
          <Form.Label column sm={2}>
            Alert Name <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              name="alertName"
              value={alertData.alertName}
              onChange={handleChange}
              placeholder="Enter Alert Name"
              isInvalid={!!errors.alertName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.alertName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="description" className="mb-3">
          <Form.Label column sm={2}>
            Description <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              name="description"
              value={alertData.description}
              onChange={handleChange}
              rows={3}
              placeholder="Enter Description"
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <SelectFormGroup
          controlId="alertType"
          label="Alert Type"
          options={alertType}
          value={alertData.alertType}
          onChange={handleChange}
          error={errors.alertType}
          placeholder="Select Alert Type"
          isInvalid={!!errors.alertType}
        />
        {alertData.alertType === "threshold" && (
          <div className="alert-message ">
            Alert if data is above or below predefined thresholds.
          </div>
        )}
        {alertData.alertType === "form" && (
          <div className="alert-message ">
            Alert when a form is not filled out in time.
          </div>
        )}
        {alertData.alertType === "lab_test" && (
          <div className="alert-message">
            Alert when a lab test is not filled out in time.
          </div>
        )}
        {alertData.alertType === "mqtt" && (
          <div className="alert-message">
            Alert if the MQTT broker fails to send data to Ensaras servers.
          </div>
        )}

        {renderAdditionalFields()}
        <>
          <SelectFormGroup
            controlId="frequency"
            label="Frequency"
            options={frequencyType || []}
            value={alertData.frequency}
            onChange={handleChange}
            error={errors.frequency}
            placeholder="Select Frequency"
            isInvalid={!!errors.frequency}
            isDisabled={frequencyType && frequencyType.length === 1}
          />
          <SelectFormGroup
            controlId="repeatIfUnsolved"
            label="Repeat if Unsolved"
            options={repeatType || []}
            value={alertData.repeatIfUnsolved}
            onChange={handleChange}
            error={errors.repeatIfUnsolved}
            placeholder="Select an Option"
            isInvalid={!!errors.repeatIfUnsolved}
            isDisabled={repeatType && repeatType.length === 1}
          />
        </>
        <Form.Group as={Row} controlId="assignedUsers" className="mb-3">
          <Form.Label column sm={2}>
            Assign To <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Select
              name="assignedUsers"
              value={null}
              onChange={handleAssignUser}
              options={users}
              placeholder="Type Email to Add the User"
              isOptionDisabled={(option) =>
                assignedUsers.some((u) => u.userId === option.value)
              }
              classNamePrefix={
                errors.assignedUsers ? "invalid" : "select-dropdown"
              }
              menuPlacement="auto"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
            {errors.assignedUsers && (
              <div className="invalid-feedback d-block">
                {errors.assignedUsers}
              </div>
            )}
            <div className="assigned-users-box mt-3">
              <ListGroup>
                <ListGroup.Item className="assigned-users-label">
                  <div className="assign-title">Assigned Users</div>
                  <div className="assign-items-name">
                    {assignedUsers.map((user, index) => (
                      <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {user.userName}
                        <Button
                          variant="link"
                          className="text-danger"
                          onClick={() => handleRemoveAssignedUser(user.userId)}
                        >
                          <RxCrossCircled />
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="notificationPreference"
          className="mb-3 align-items-center"
        >
          <Form.Label column sm={2}>
            Notification
            <br />
            <span className="notification-label">Preferences</span>{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <div className="notification-gtp">
              {["Email", "WhatsApp"].map((preference) => (
                <label
                  key={preference}
                  className={`custom-checkbox ${
                    errors.notificationPreference ? "invalid" : ""
                  }`}
                >
                  <span>{preference}</span>
                  <Form.Check
                    className="check-inner"
                    name="notificationPreference"
                    value={preference}
                    checked={alertData.notificationPreference.includes(
                      preference
                    )}
                    onChange={handleNotification}
                    isInvalid={!!errors.notificationPreference}
                  />
                </label>
              ))}
            </div>
            {errors.notificationPreference && (
              <div className="invalid-feedback d-block">
                {errors.notificationPreference}
              </div>
            )}
          </Col>
        </Form.Group>

        <div className="alt-buttons" style={{ marginTop: "20px" }}>
          {editAlert ? (
            <>
              <Button
                variant="outline-secondary"
                onClick={handleSave}
                style={{ marginRight: "10px" }}
                disabled={!isAlertChange}
              >
                Edit
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  onAlertManagement();
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                onClick={handleSave}
                style={{ marginRight: "10px" }}
              >
                Save
              </Button>
              <Button variant="outline-secondary" onClick={handleClear}>
                Clear
              </Button>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AlertManagement;
