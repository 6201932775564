import axios from 'axios';
import config from '../config';
import api from '../interceptors/axios'

async function createAlert(data) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.post(`/api/alerts`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw new Error(error.response?.data || error.response?.statusText || 'An error occurred');
    }
}
async function updateAlert(data, alertId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.put(`/api/alerts/${alertId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || error.response?.statusText || 'An error occurred');
    }
}

async function orgAlertList(orgId, timeRange, page, pageSize) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/org-alerts/${orgId}`, {
            params: {
                range: timeRange,
                page: page,
                pageSize: pageSize
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'An error occurred');
    }
}

async function deleteAlert(alertId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.delete(`/api/alerts/${alertId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}


export { orgAlertList, deleteAlert, createAlert, updateAlert };