import axios from 'axios';
import config from '../config';
import api from '../interceptors/axios'

async function deleteTeam(data) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.delete(`/api/team`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: data, // Axios requires 'data' field for DELETE requests with a body
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

export { deleteTeam };
