import axios from "axios";
import config from '../config';

let refresh = false;

// axios.interceptors.response.use(resp => resp, async error => {
//     if (error.response.status === 401 && !refresh) {
//         refresh = true;

//         try {
//             const response = await axios.post(`${config.apiUrl}/token/refresh/`, {
//                 refresh: localStorage.getItem('refresh_token')
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 // withCredentials: true
//             });
// console.log("response--->", response)
//             if (response.status === 200) {
//                 axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
//                 localStorage.setItem('token', response.data.access);
//                 localStorage.setItem('refresh_token', response.data.refresh);

//                 // Retry the original request
//                 error.config.headers['Authorization'] = `Bearer ${response.data.access}`;
//                 return axios(error.config);
//             } else if(response.status && response.status===403) {
//                 if (response.message==="Unauthorized") {
//                     localStorage.clear();
//                     // localStorage.removeItem('refresh_token');
//                     window.location.href = '/signin'    
//                 }
//             }
//                 else{
//                 localStorage.removeItem('token');
//                 localStorage.removeItem('refresh_token');
//                 window.location.href = '/signin';
//             }
//         } catch (refreshError) {
//             console.log(refreshError, '=========');
          
//             localStorage.removeItem('token');
//             localStorage.removeItem('refresh_token');
//             window.location.href = '/signin';
//             return Promise.reject(refreshError);
//         } finally {
//             refresh = false;
//         }
//     } else {
//         refresh = false;
//     }

//     return Promise.reject(error);
// });

const api = axios.create({
    baseURL: `${config.apiUrl}`, // Use the appropriate base URL from your config
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            const { message } = error.response.data;
            console.log(message);
            if (message === "Unauthorized") {
                localStorage.clear();
                window.location.href = '/signin';
            }
            throw new Error(error.response?.data || 'An error occurred');
        }
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/signin';
        }
        console.log(error.response);
        // throw new Error(error.response?.data || 'An error occurred');
        return Promise.reject(error);
    }
);

export default api

// export default axios;
