import React, { useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { isEmail } from "validator";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import logo from "../../assets/logo.svg";
import logo1 from "../../assets/logo1.svg";
import { signIn } from "../../hooks/signin";
import { signout } from "../../hooks/signout";
import AppFooter from "../../components/footer";
import MessagePopup from "../../components/messagePopup";
import "./style.css";

export const SignInPage = ({ token, setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  useEffect(() => {
    const previousEmail = localStorage.getItem("email");
    const previousRememberMe = localStorage.getItem("rememberMe");
    if (previousRememberMe) {
      setEmail(previousEmail);
      setRememberMe(previousRememberMe === "true");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

    // deleting cookie will not work as it is a http only cookie which can't be acces on the client side and it can be handled only on server side.
    const deleteCookie = (name) => {
      Cookies.remove(name);
      // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
    };

  const clearEmail = () => {
    setEmail("");
    setEmailError("");
  };

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isEmail(email)) {
      setEmailError("Invalid Email Address.");
      hasError = true;
    } else {
      setEmailError("");
    }
  
    if (!password) {
      setPasswordError("Password is required.");
      hasError = true;
    } else {
      setPasswordError("");
    }
  
    if (hasError) {
      return;
    }

    await signout();
    localStorage.clear();

    try {
      const response = await signIn({ email, password });
      if (response.token) {
        localStorage.setItem("Response", JSON.stringify(response));
        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("rememberMe", "true");
        }
        localStorage.setItem("token", response.token);
        setToken(response.token);
        localStorage.setItem("username", response.username);
        localStorage.setItem("userId", response.user_id);
        localStorage.setItem("role", response.role);
        localStorage.setItem("refresh_token", response.refresh);
        if(sessionStorage.getItem('redirectUrl')){
          window.location.href = sessionStorage.getItem('redirectUrl');
          sessionStorage.removeItem('redirectUrl');
        }
        else{
          window.location.href = "/dashboard";
        }
      }
    } catch (error) {
      updateMessage("error", error.message);
    }
  };

  return (
    <>
      <div className="login-header">
        <a href="/" className="logo">
          <img src={logo} alt="Ensaras Logo" className="logo desk-logo" />
          <img src={logo1} alt="Ensaras Logo" className="logo mob-logo" />
        </a>
      </div>
      <div className="signin-wrapper">
        <div className="login-outer">
          <h1 className="signin-text">ENSARAS</h1>
          <p className="signin-p">Please fill your detail to access your account.</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="form-field email-sect">
              <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
              <div className="email-field">
                <Form.Control
                  // type="email"
                  isInvalid={!!emailError}
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmailError("");
                    setEmail(e.target.value);
                  }}
                />
               {!emailError && <span className="email-clear-icon" onClick={clearEmail}>
                  <RxCrossCircled />
                </span>}
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="form-field signin-sect">
              <Form.Label>Password <span className="text-danger">*</span></Form.Label>
              <div className="password-field">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {setPassword(e.target.value); setPasswordError("");}}
                  isInvalid={!!passwordError}
                />
               {!passwordError &&<span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>}
                <Form.Control.Feedback type="invalid">
                  Password is required.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <div className="signin-box-container signin-sect">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
              </Form.Group>
              <a href="/reset-password" className="fgt-pwd">
                Forgot Password?
              </a>
            </div>
            <Button variant="primary" type="submit" block className="signin-sect">
              Sign In
            </Button>
          </Form>
        </div>
      </div>
      <MessagePopup message={message} messageType={messageType} onClose={() => setMessage("")} />
      <AppFooter />
    </>
  );
};

