import axios from 'axios';
import config from '../config';
import api from '../interceptors/axios'

async function signout() {
    // Retrieve refresh_token from localStorage
    const refreshToken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');

    if (refreshToken && token) {
        try {
            const response = await api.post(`/api/signout`, {
                refresh_token: refreshToken
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                mode: 'cors'
            });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            return response.data;
        } catch (error) {
            console.error('Error during signout:', error);
        }
    }
}

export { signout };
