import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

const SelectFormGroup = ({ controlId, label, options, value, onChange, error, placeholder, isInvalid ,isDisabled=false}) => {
  return (
    <Form.Group as={Row} controlId={controlId} className="mb-3">
      <Form.Label column sm={2}>
        {label} <span className="text-danger">*</span>
      </Form.Label>
      <Col sm={10}>
        <Select
          name={controlId}
          options={options}
          value={options.find(option => option.value === value) || null}
          onChange={selectedOption => onChange({ target: { name: controlId, value: selectedOption ? selectedOption.value : '' } })}
          placeholder={placeholder}
          isClearable
          isDisabled={isDisabled}
          classNamePrefix={isInvalid ? "invalid" : "select-dropdown"}
          menuPlacement="auto"
          styles={{
            menuList: base => ({
              ...base,
              maxHeight: '200px', // Limit height of dropdown (around 6 items)
              overflowY: 'auto', // Add scroll after maxHeight
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            dropdownIndicator: base => ({
              ...base,
              display: options.length === 1 ? 'none' : 'block', // Hide dropdown icon if options length is 1
            }),
          }}
        />

        
        {error && <div className="invalid-feedback d-block">{error}</div>}
        
      </Col>
    </Form.Group>
  );
};

export default SelectFormGroup;