import React, { useState, useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import AlertManagement from "../manageAlert";
import { orgAlertList } from "../../hooks/alertSystem";
import { IoArrowBackSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { deleteAlert } from "../../hooks/alertSystem";
import MessagePopup from "../messagePopup";
import DeleteConfirmModal from "../../models/deleteConfirmModal";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import Loader from "../../common/loader";
import alertManagementArrow from "../../assets/alt-mgt-ic.svg";


const AlertSystem = ({ alertManagePage, setAlertManagePage, editMode, setEditMode }) => {
  const orgInfo = useSelector((state) => state.org.orgData);
  const [alertList, setAlertList] = useState([]);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [editAlert, setEditAlert] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);
  const dispatch = useDispatch();
  const orgId = orgInfo?.orgId;

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleDelete = (id) => {
    dispatch(showLoader());
    deleteAlert(id)
      .then((response) => {
        setAlertList((prevList) => prevList.filter((alert) => alert.id !== id));
        dispatch(hideLoader());
        updateMessage("success", response.message);
      })
      .catch((error) => {
        console.error("Error fetching forms:", error);
        updateMessage(
          "error",
          error.message || "Error occured while deleting alert"
        );
        dispatch(hideLoader());
      });
  };

  const handleConfirmDelete = () => {
    if (alertToDelete) {
      handleDelete(alertToDelete.id);
      setShowDeleteModal(false);
      setAlertToDelete(null);
    }
  };

  useEffect(() => {
    // Fetch team options from the API when `alertManagePage` changes
    if (!alertManagePage) {
      dispatch(showLoader());
      orgAlertList(orgId)
        .then((response) => {
          if (response && response.success) {
            if (response && response.data && response.data.length > 0) {
              setAlertList(response.data);
            }
            dispatch(hideLoader());
          }
          if (response && !response.success) {
            dispatch(hideLoader());
          }
        })
        .catch((error) => {
          console.error("Error fetching alert list:", error);
          dispatch(hideLoader());
        });
    }
  }, [alertManagePage, orgId, dispatch]);

  return (
    <div className="alt-main-outer">
      <Loader />
      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
      {alertManagePage ? (
        <div className="back-alt">
          <div className="back-alt-inner">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAlertManagePage(false)}
            >
              <IoArrowBackSharp /> Back
            </Button>
          </div>
          <div className="back-arrow">
            <i>
              <IoArrowBackSharp onClick={() => setAlertManagePage(false)} />
            </i>
          </div>
          <AlertManagement
            orgInfo={orgInfo}
            editAlert={editAlert}
            onAlertManagement={() => setAlertManagePage(false)}
            updateMessage={updateMessage}
          />
        </div>
      ) : (
        <div className="alt-mgt-outer">
          <div className="d-flex alt-mg-inner justify-content-between mb-3 align-items-center">
            <div className="alt-mgt-title">
              <span className="alert-mgt-arrow">
                <img src={alertManagementArrow} alt="Alert Management Logo" />
              </span>
              <h2>Alert Management</h2>
            </div>
            <div className="alt-mgt-edit">
              {editMode ? (
                <Button variant="success" onClick={() => setEditMode(false)}>
                  Finish Edit
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  onClick={() => setEditMode(true)}
                >
                  Edit Alerts
                </Button>
              )}
            </div>
          </div>

          {!editMode && (
            <div className="d-flex create-mgt-alt justify-content-between mb-5">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setEditAlert(null);
                  setAlertManagePage(true);
                }}
              >
                Create New Alert
              </Button>
            </div>
          )}
          <div className="alt-mgt-title mb-3">
            <h4>Active alerts</h4>
          </div>
          <ListGroup>
            {alertList?.map((alert) => (
              <ListGroup.Item
                key={alert.id}
                className="d-flex justify-content-between align-items-center alert-item"
              >
                {alert.alert_name.length > 20
                  ? `${alert.alert_name.substring(0, 20)}...`
                  : alert.alert_name}
                {editMode && (
                  <div className="control-btns">
                    <Button
                      variant="link"
                      onClick={() => {
                        setEditAlert(alert); // Set the alert to be edited
                        setAlertManagePage(true); // Show the edit page
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="link"
                      // className="text-danger"
                      onClick={() => {
                        setAlertToDelete(alert);
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
      <DeleteConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        itemName={alertToDelete?.alert_name}
        moduleName="Alert"
      />
    </div>
  );
};

export default AlertSystem;
