import axios from "axios";
import config from "../config";
import api from '../interceptors/axios'

async function addUser(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post(`/api/user`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data || error.response?.statusText || "Failed to add user!"
    );
  }
}

export { addUser };
