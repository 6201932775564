import axios from "axios";
import config from "../config";
import api from '../interceptors/axios'

async function resendOTP(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post(
      `/api/resendOTP`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.statusText || "An error occurred");
  }
}

export { resendOTP };
