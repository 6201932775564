import React, { useEffect, useState } from "react";
import { Table, Dropdown, Row, Col, Button } from "react-bootstrap";
import AppNavBar from "../../components/navbar";
import { FaArrowLeft } from "react-icons/fa6";
import AppFooter from "../../components/footer";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { teamAlertList } from "../../hooks/teamInfo";
import "./style.css";
import previous from "../../assets/pagination-left.svg";
import next from "../../assets/pagination-right.svg";
import Select from "react-select";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import MessagePopup from "../../components/messagePopup";
import { downloadAlertData } from "../../hooks/dataDownload";

const AlertList = () => {
  const [isWideEnough, setIsWideEnough] = useState(window.innerWidth > 991);
  const [currentPage, setCurrentPage] = useState(1);
  const [alertData, setAlertData] = useState([]);
  const [dateFilter, setDateFilter] = useState("30d");
  const [alertFilter, setAlertFilter] = useState("all");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [teamName, setTeamName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const activeTab = localStorage.getItem("activeTab");
  const [teamTimeZone, setTeamTimeZone] = useState("")
  const location = useLocation();
    const mqttTeams = [4, 5, 6]; //prodMqtt Teams
   //const mqttTeams = [247, 37, 48];

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideEnough(window.innerWidth > 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      setDownloadData("");
      downloadAlertData(id, {
        dateFilter: dateFilter,
        alertType: alertFilter,
      })
        .then((response) => {
          if (typeof response == "string") {
            response = response.replace(/\bNaN\b/g, "null");
            response = JSON.parse(response);
          }
          let resonseClone = [];

          if (response && response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              const alertObject = {
                "Alert Type": alertTypeName.find(
                  (type) => type.value === item.alertType
                )?.label,
                "Alert Name": item.alertName,
                Description: item.description,
              };
              if (
                [
                  "threshold_resolved",
                  "threshold_all",
                  "threshold_active",
                ].includes(alertFilter)
              ) {
                alertObject["Variable"] = item.variable;
              }
              if (
                ["mqtt_resolved", "mqtt_active", "mqtt_all"].includes(
                  alertFilter
                )
              ) {
                alertObject["MQTT"] = item.mqtt;
              }
              console.log(alertFilter);
              if (alertFilter === "form") {
                alertObject["Form Name"] = item.form || "N/A";
              }
              if (alertFilter === "lab_test") {
                if (
                  item.lacationParameterPair &&
                  item.lacationParameterPair.location &&
                  item.lacationParameterPair.parameter
                ) {
                  alertObject[
                    "Location-Parameter Pairs"
                  ] = `${item.lacationParameterPair.location} : ${item.lacationParameterPair.parameter}`;
                } else {
                  alertObject["Location-Parameter Pairs"] = "N/A";
                }
              }
              if (alertFilter === "all") {
                // alertObject["Refrence"] =
                //   item.alertType === "form"
                //     ? item.formName
                //     : item.alertType === "mqtt"
                //     ? item.mqtt
                //     : item.alertType === "threshold"
                //     ? item.variable
                //     : item.alertType === "lab_test" &&
                //       item.lacationParameterPair &&
                //       Object.keys(item.lacationParameterPair).length
                //     ? `${item.lacationParameterPair.location} : ${item.lacationParameterPair.parameter}`
                //     : "N/A";

                if (item.alertType === "form") {
                  alertObject["Refrence"] = item.form || "N/A";
                } else if (item.alertType === "mqtt") {
                  alertObject["Refrence"] = item.mqtt;
                } else if (item.alertType === "threshold") {
                  alertObject["Refrence"] = item.variable;
                } else if (
                  item.alertType === "lab_test" &&
                  item.lacationParameterPair &&
                  Object.keys(item.lacationParameterPair).length
                ) {
                  alertObject[
                    "Refrence"
                  ] = `${item.lacationParameterPair.location} : ${item.lacationParameterPair.parameter}`;
                } else {
                  alertObject["Refrence"] = "N/A";
                }
              }

              alertObject["Status"] = getStatusText(item);
              alertObject["Activation Time"] = item.activationTime
                ? timeFormatter(item.activationTime)
                : "-";
              if (conditionStatus.includes(alertFilter)) {
                alertObject["Resolution Time"] = item.resolutionTime
                  ? timeFormatter(item.resolutionTime)
                  : "N/A";
                alertObject["Alert Duration"] = item.duration
                  ? item.duration
                  : "N/A";
              }

              // if (!alertObject["Refrence"]) {
              //   console.log("item.alertType :: ", item.alertType);
              //   console.log("item :: ", item);
              // }
              resonseClone.push(alertObject);
            });
          } else {
            console.log("chnages--->", response.data);
            // Set headers based on the filter when data is empty
            resonseClone = [
              { "Alert Type": "", "Alert Name": "", Description: "" },
            ];

            if (
              [
                "threshold_resolved",
                "threshold_all",
                "threshold_active",
              ].includes(alertFilter)
            ) {
              resonseClone.push({ Variable: "" });
            }

            if (
              ["mqtt_resolved", "mqtt_active", "mqtt_all"].includes(alertFilter)
            ) {
              resonseClone.push({ MQTT: "" });
            }

            if (alertFilter === "form") {
              resonseClone.push({ "Form Name": "" });
            }

            if (alertFilter === "lab_test") {
              resonseClone.push({ "Location-Parameter Pairs": "" });
            }

            if (alertFilter === "all") {
              resonseClone.push({ Refrence: "" });
            }

            resonseClone.push({ Status: "", "Activation Time": "" });

            if (conditionStatus.includes(alertFilter)) {
              resonseClone.push({
                "Resolution Time": "",
                "Alert Duration": "",
              });
            }
            console.log("chnages--->", resonseClone);
          }
          const outputData = JSON.parse(JSON.stringify(resonseClone));
          // console.log(
          //   "outputData :: ",
          //   outputData.filter((item) => item["Alert Type"] == "Form")
          // );
          setDownloadData(outputData);
          setLoading(false);
        })
        .catch((error) => {
          updateMessage(
            "error",
            error.message || "Failed to prepare for data downloading!"
          );
          setLoading(false);
        });
    }
  }, [loading, id]);
  const dateFilters = [
    {
      value: "30d",
      label: "Last 30 days",
    },
    {
      value: "60d",
      label: "Last 60 days",
    },
    {
      value: "90d",
      label: "Last 90 days",
    },
    {
      value: "6m",
      label: "Last 6 months",
    },
    {
      value: "1y",
      label: "Last 1 year",
    },
    {
      value: "2y",
      label: "Last 2 years",
    },
    {
      value: "5y",
      label: "Last 5 years",
    },
  ];

  let alertTypes = [
    { label: "All Alerts", value: "all" },
    { label: "Form", value: "form" },
    { label: "Lab Test", value: "lab_test" },
    { label: "Threshold Active", value: "threshold_active" },
    { label: "Threshold Resolved", value: "threshold_resolved" },
    { label: "Threshold All", value: "threshold_all" },
  ];

  if(mqttTeams.includes(Number(id))){
    alertTypes=[
      ...alertTypes,
    { label: "MQTT Resolved", value: "mqtt_resolved" },
    { label: "MQTT Active", value: "mqtt_active" },
    { label: "MQTT All", value: "mqtt_all" },
    ]
  }

  const alertTypeName = [
    { label: "Form", value: "form" },
    { label: "Lab Test", value: "lab_test" },
    { label: "Threshold", value: "threshold" },
    { label: "MQTT", value: "mqtt" },
  ];
  const conditionStatus = [
    "threshold_resolved",
    "threshold_all",
    "threshold_active",
    "all",
    "mqtt_active",
    "mqtt_resolved",
    "mqtt_all",
  ];

  const handleRef = (link) => {
    if (link && downloadData && !loading) {
      link.link.click(); // Trigger the CSV download
    }
    setDownloadData("");
  };

  const getStatusText = (alert) => {
    const { alertType, status, activationTime } = alert;
    switch (alertType) {
      case "mqtt":
        return status === "Unsolved" ? "Disconnect" : "Connected";
      case "threshold":
        return status === "Unsolved" ? "Active" : "Resolved";
      case "lab_test":
        return status === "Unsolved"
          ? `Incomplete on ${dateFormatter(activationTime)}`
          : status;
      case "form":
        return status === "Unsolved"
          ? `Incomplete on ${dateFormatter(activationTime)}`
          : status;
      default:
        return status;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orgIdFromQuery = queryParams.get("orgId");
    setOrgId(orgIdFromQuery);
    console.log(dateFilter, currentPage, pageSize, alertFilter);
    if (!currentPage) {
      return;
    }

    dispatch(showLoader());
    teamAlertList(id, {
      dateFilter: dateFilter,
      page: currentPage,
      limit: pageSize || 20,
      alertType: alertFilter,
    })
      .then((response) => {
        if (response && response.success) {
          setAlertData(response.data || []);
          setPageCount(response.pageCount);
          setTeamName(response.teamName);
          setTeamTimeZone(response.teamTimezone)
          // setOrgId(response.orgId);
        }
        if (response && !response.success) {
          console.log("Error fetching alert list:", response);
        }
        dispatch(hideLoader());
      })
      .catch((error) => {
        console.error("Error fetching alert list:=====", error);
        dispatch(hideLoader());
      });
  }, [id, dateFilter, alertFilter, currentPage, pageSize, dispatch]);

  const handlePageChange = (value) => {
    const page = parseInt(value, 10);
    if (!isNaN(page) && page >= 1 && page <= pageCount) {
      setCurrentPage(page);
    }
  };

  const timeFormatter = (time) => {
    if (!time) return "";
    const date = new Date(time);
    let formatDateTime = date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: teamTimeZone, // "America/New_York" for example
    });
    formatDateTime = formatDateTime.replace(/(\w{3})(\s)/, "$1. ");
    return formatDateTime;
//     const momentWithoutOffset = moment.utc(timeWithOffset.substring(0, timeWithOffset.length - 6)); // Remove the +04:00

// const utcTimeString = momentWithoutOffset.format('YYYY-MM-DDTHH:mm:ss.SSSZ')

// const utcTimeStringWithoutZ = momentWithoutOffset.format('YYYY-MM-DDTHH:mm:ss.SSS')

// console.log('Original Time with Offset:', timeWithOffset);
// console.log('UTC Time (keeping original time value, with Z):', utcTimeString);
// console.log('UTC Time (keeping original time value, without Z):', utcTimeStringWithoutZ);

// const utcMomentObject = moment.utc(timeWithOffset.substring(0, timeWithOffset.length - 6));
// console.log("UTC Moment Object:", utcMomentObject);
//     return utcMomentObject;
  };

  // const dateFormatter = (dateString) => {
  //   if (!dateString) return "";
  //   const options = { year: "numeric", month: "short", day: "numeric" };
  //   const date = new Date(dateString);
  //   let formattedDate = date.toLocaleDateString("en-US", options);
  //   formattedDate = formattedDate.replace(/(\w{3})(\s)/, "$1. ");
  //   return formattedDate;
  // };

  const dateFormatter = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);

    // Decrease one day (1 day = 24 * 60 * 60 * 1000 milliseconds)
    date.setDate(date.getDate() - 1);

    let formattedDate = date.toLocaleDateString("en-US", options);
    formattedDate = formattedDate.replace(/(\w{3})(\s)/, "$1. ");
    return formattedDate;
  };

  if (isWideEnough) {
    return (
      <>
        <AppNavBar />
        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        />
        <div className="alertList-container">
          <div className="alert-table-outer">
            <div className="alert-table-title">
              <h4>
                <a
                  href={`/organization/${orgId}?activeTab=${activeTab}`}
                  className="list-arrow"
                >
                  <FaArrowLeft />{" "}
                </a>
                Alert List <span>({teamName})</span>
              </h4>
            </div>

            <div className="alert-table-tools">
              <Row className="mb-3">
                <Col xs={12} md={6} className="mb-2 alert-table-title-lgt">
                  <label>Range of Time of Data Entry : </label>
                  <Select
                    classNamePrefix="react-select"
                    aria-label="Date Filter Dropdown"
                    value={
                      dateFilters.find((type) => type.value === dateFilter) ||
                      null
                    }
                    onChange={(selectedOption) =>
                      setDateFilter(selectedOption?.value)
                    }
                    options={dateFilters}
                    placeholder="Select Alert Type"
                  />
                  {(localStorage.getItem("role") === "Ensaras Admin" ||
                    localStorage.getItem("role") === "Client Admin") && (
                    <div className="download-btn-outer">
                      <button
                        className="download-btn"
                        onClick={() => setLoading(true)}
                        disabled={loading}
                      >
                        <IoCloudDownloadOutline />
                        {loading ? "Loading..." : "Download"}
                      </button>
                      {downloadData && (
                        <CSVLink
                          data={downloadData}
                          filename="download.csv"
                          ref={handleRef}
                          target="_blank"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  )}
                </Col>
                <Col className="alert-table-title-rgt">
                  <div className="alter-rgt-select">
                    <Select
                      classNamePrefix="react-select"
                      aria-label="Alert Type Filter Dropdown"
                      value={
                        alertTypes.find((type) => type.value === alertFilter) ||
                        null
                      }
                      onChange={(selectedOption) => {
                        setAlertFilter(selectedOption?.value);
                        setCurrentPage(1);
                      }}
                      options={alertTypes}
                      placeholder="Select Alert Type"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            {/* Table */}
            <Table bordered responsive className="alert-table">
              <thead className="table-dark">
                <tr>
                  <th>Alert Type</th>
                  <th>Alert Name</th>
                  <th>Description</th>
                  {alertFilter === "form" && <th>Form Name</th>}
                  {[
                    "threshold_resolved",
                    "threshold_all",
                    "threshold_active",
                  ].includes(alertFilter) && <th>Variable</th>}
                  {["mqtt_resolved", "mqtt_active", "mqtt_all"].includes(
                    alertFilter
                  ) && <th>MQTT</th>}
                  {alertFilter === "lab_test" && (
                    <th>Location-Parameter Pairs</th>
                  )}
                  {alertFilter === "all" && <th>Reference</th>}
                  <th>Status</th>
                  <th>Activation Time</th>
                  {conditionStatus.includes(alertFilter) && (
                    <>
                      <th>Resolution Time</th>
                      <th>Alert Duration</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {alertData.map((alert, index) => (
                  <tr key={index}>
                    <td>
                      {
                        alertTypeName.find(
                          (type) => type.value === alert.alertType
                        )?.label
                      }
                    </td>
                    <td>{alert.alertName}</td>
                    <td>{alert.description}</td>
                    {alertFilter === "form" && <td>{alert?.form || "N/A"}</td>}
                    {[
                      "threshold_resolved",
                      "threshold_all",
                      "threshold_active",
                    ].includes(alertFilter) && (
                      <td>{alert?.variable || "N/A"}</td>
                    )}
                    {["mqtt_resolved", "mqtt_active", "mqtt_all"].includes(
                      alertFilter
                    ) && <td>{alert.mqtt || "N/A"}</td>}
                    {alertFilter === "lab_test" && (
                      // <td>{alert.lacationParameterPair || "N/A"}</td>
                      <td>
                        {alert.lacationParameterPair?.location &&
                        alert.lacationParameterPair?.parameter
                          ? `${alert.lacationParameterPair.location} : ${alert.lacationParameterPair.parameter}`
                          : "N/A"}
                      </td>
                    )}
                    {alertFilter === "all" && (
                      <td>
                        <>
                          {alert.alertType === "form"
                            ? alert.form || "N/A"
                            : null}
                          {alert.alertType === "mqtt"
                            ? alert.mqtt || "N/A"
                            : null}
                          {alert.alertType === "threshold"
                            ? alert.variable || "N/A"
                            : null}
                          {alert.alertType === "lab_test"
                            ? Object.keys(alert.lacationParameterPair).length
                              ? `${alert.lacationParameterPair?.location} : ${alert.lacationParameterPair?.parameter}`
                              : "N/A"
                            : null}
                          {!["form", "mqtt", "threshold", "lab_test"].includes(
                            alert.alertType
                          ) && "N/A"}
                        </>
                      </td>
                    )}
                    <td>{getStatusText(alert)}</td>
                    <td>
                      {alert.activationTime
                        ? timeFormatter(alert.activationTime)
                        : "-"}
                    </td>
                    {conditionStatus.includes(alertFilter) && (
                      <>
                        <td>
                          {alert.resolutionTime
                            ? timeFormatter(alert.resolutionTime)
                            : "N/A"}
                        </td>

                        <td>{alert.duration ? alert.duration : "N/A"}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {alertData.length === 0 && (
              <div className="no-form-data"> Data not found</div>
            )}

            {alertData && alertData.length > 0 && (
              <div className="pagination-outer">
                <div>&nbsp; </div>
                <div className="paginations-inner">
                  <Button
                    variant="secondary"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <img src={previous} alt="Previous" />
                  </Button>
                  <input
                    type="text"
                    className="current-page-input"
                    value={currentPage}
                    onChange={(e) => {
                      const value = e.target.value;
                      const page = value === "" ? "" : parseInt(value, 10);
                      if (page === "" || (page >= 1 && page <= pageCount)) {
                        setCurrentPage(page);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      const page = value === "" ? 1 : parseInt(value, 10);
                      if (page < 1 || page > pageCount) {
                        setCurrentPage(1);
                      } else {
                        setCurrentPage(page);
                      }
                    }}
                    min={1}
                    max={pageCount}
                  />

                  <Button
                    variant="secondary"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === pageCount}
                  >
                    <img src={next} alt="Next" />
                  </Button>
                  <span>of {pageCount} pages</span>
                </div>
                <div className="pagesize-ctn">
                  <label htmlFor="pageSize">Showing</label>
                  {/* <input
                    type="number"
                    id="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPageSize(value === "" ? "" : parseInt(value, 10));
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setPageSize(20);
                      }
                    }}
                    min={1}
                    className="pagesize-box"
                  /> */}

                  <input
                    type="text"
                    id="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only positive integers
                      if (/^\d+$/.test(value)) {
                        const intValue = parseInt(value, 10);
                        if (intValue > 0) {
                          setPageSize(intValue);
                        }
                      } else if (value === "") {
                        setPageSize(""); // Allow clearing the input
                      }
                    }}
                    onBlur={(e) => {
                      // Set default page size if input is empty
                      if (e.target.value === "") {
                        setPageSize(20);
                      }
                    }}
                    pattern="\d+" // Allows only digits
                    title="Enter a positive number"
                    min={1}
                    className="pagesize-box"
                  />

                  <label htmlFor="pageSize">items per page</label>
                </div>
              </div>
            )}
          </div>
        </div>
        <AppFooter />
      </>
    );
  } else {
    return (
      <>
        <AppNavBar
          needBack={true}
          backLink={`/organization/${orgId}?activeTab=${activeTab}`}
        />
        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        />
        <div className="alertList-container">
          <div className="alert-table-outer">
            <div className="alert-table-title mb-2">
              <h4>
                Alert List <span>({teamName})</span>
              </h4>
            </div>
            {/* Filters */}
            <div className="alert-table-title-rgt">
              <div className="aalert-table-outer-inner">
                <div className="mb-2 alert-left-mobile">
                  <label>Range of Time of Data Entry : </label>
                  <div className="alert-left-mob-select">
                    <Select
                      classNamePrefix="react-select"
                      aria-label="Date Filter Dropdown"
                      value={
                        dateFilters.find((type) => type.value === dateFilter) ||
                        null
                      }
                      onChange={(selectedOption) =>
                        setDateFilter(selectedOption?.value)
                      }
                      options={dateFilters}
                      placeholder="Select Alert Type"
                    />
                  </div>

                  {(localStorage.getItem("role") === "Ensaras Admin" ||
                    localStorage.getItem("role") === "Client Admin") && (
                    <div className="download-btn-outer">
                      <button
                        className="download-btn"
                        onClick={() => setLoading(true)}
                        disabled={loading}
                      >
                        <IoCloudDownloadOutline />
                        {loading ? "Loading..." : "Download"}
                      </button>
                      {downloadData && (
                        <CSVLink
                          data={downloadData}
                          filename="download.csv"
                          ref={handleRef}
                          target="_blank"
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="alert-rgt-mobile">
                  <div className="alt-rgt-mob-select">
                    <Select
                      classNamePrefix="react-select"
                      aria-label="Alert Type Filter Dropdown"
                      value={
                        alertTypes.find((type) => type.value === alertFilter) ||
                        null
                      }
                      onChange={(selectedOption) =>
                        setAlertFilter(selectedOption?.value)
                      }
                      options={alertTypes}
                      placeholder="Select Alert Type"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Table */}
            {alertData.length === 0 && (
              <div className="no-data">Data not found</div>
            )}
            {alertData.map((alert, index) => (
              <Row
                key={index}
                className={`alert-row ${
                  index % 2 === 0 ? "row-even" : "row-odd"
                } mb-4`}
              >
                <Col xs={6} className="field-names mb-2">
                  Alert Type
                </Col>
                <Col xs={6} className="text-center mb-2">
                  {
                    alertTypeName.find((type) => type.value === alert.alertType)
                      ?.label
                  }
                </Col>
                <Col xs={6} className="field-names mb-2">
                  Alert Name
                </Col>
                <Col xs={6} className="text-center mb-2">
                  {alert.alertName}
                </Col>
                <Col xs={6} className="field-names mb-2">
                  Description
                </Col>
                <Col xs={6} className="text-center mb-2">
                  {alert.description}
                </Col>
                {alertFilter === "all" && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      Reference
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      <>
                        {alert.alertType === "form" && alert.form}
                        {alert.alertType === "mqtt" && alert.mqtt}
                        {alert.alertType === "threshold" && alert.variable}
                        {/* {alert.alertType === "lab_test" && "N/A"} */}
                        {alert.alertType === "lab_test" &&
                        alert.lacationParameterPair?.location &&
                        alert.lacationParameterPair?.parameter
                          ? `${alert.lacationParameterPair.location} : ${alert.lacationParameterPair.parameter}`
                          : null}
                        {!["form", "mqtt", "threshold", "lab_test"].includes(
                          alert.alertType
                        ) && "N/A"}
                      </>
                    </Col>
                  </>
                )}
                {alertFilter === "form" && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      Form Name
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert.form || "N/A"}
                    </Col>
                  </>
                )}
                {[
                  "threshold_resolved",
                  "threshold_all",
                  "threshold_active",
                ].includes(alertFilter) && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      Variable
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert.variable || "N/A"}
                    </Col>
                  </>
                )}
                {["mqtt_resolved", "mqtt_active", "mqtt_all"].includes(
                  alertFilter
                ) && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      MQTT
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert?.mqtt || "N/A"}
                    </Col>
                  </>
                )}
                {alertFilter === "lab_test" && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      Location-Parameter Pairs
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert.alertType === "form" ? alert.form || "N/A" : null}
                      {alert.alertType === "mqtt" ? alert.mqtt || "N/A" : null}
                      {alert.alertType === "threshold"
                        ? alert.variable || "N/A"
                        : null}
                      {alert.alertType === "lab_test" &&
                      Object.keys(alert.lacationParameterPair).length
                        ? `${alert.lacationParameterPair?.location} : ${alert.lacationParameterPair?.parameter}`
                        : null}
                      {!["form", "mqtt", "threshold", "lab_test"].includes(
                        alert.alertType
                      ) && "N/A"}
                    </Col>
                  </>
                )}
                <Col xs={6} className="field-names mb-2">
                  Status
                </Col>
                <Col xs={6} className="text-center mb-2">
                  {getStatusText(alert)}
                </Col>

                <Col xs={6} className="field-names mb-2">
                  Activation Time
                </Col>
                <Col xs={6} className="text-center mb-2">
                  {alert.activationTime
                    ? timeFormatter(alert.activationTime)
                    : "-"}
                </Col>
                {conditionStatus.includes(alertFilter) && (
                  <>
                    <Col xs={6} className="field-names mb-2">
                      Resolution Time
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert.resolutionTime
                        ? timeFormatter(alert.resolutionTime)
                        : "N/A"}
                    </Col>
                    <Col xs={6} className="field-names mb-2">
                      Alert Duration
                    </Col>
                    <Col xs={6} className="text-center mb-2">
                      {alert.duration ? alert.duration : "N/A"}
                    </Col>
                  </>
                )}
              </Row>
            ))}
          </div>
          {alertData && alertData.length > 0 && (
            <div className="pagination-outer">
              <div>&nbsp; </div>
              <div className="paginations-inner">
                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <img src={previous} alt="Previous" />
                </Button>
                <input
                  type="text"
                  className="current-page-input"
                  value={currentPage}
                  onChange={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? "" : parseInt(value, 10);
                    if (page === "" || (page >= 1 && page <= pageCount)) {
                      setCurrentPage(page);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? 1 : parseInt(value, 10);
                    if (page < 1 || page > pageCount) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                  min={1}
                  max={pageCount}
                />

                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === pageCount}
                >
                  <img src={next} alt="Next" />
                </Button>
                <span>of {pageCount} pages</span>
              </div>
              <div className="pagesize-ctn">
                <label htmlFor="pageSize">Showing</label>
                {/* <input
                  type="text"
                  id="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPageSize(value === "" ? "" : parseInt(value, 10));
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setPageSize(20);
                    }
                  }}
                  min={1}
                  className="pagesize-box"
                /> */}

                <input
                  type="text"
                  id="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only positive integers
                    if (/^\d+$/.test(value)) {
                      const intValue = parseInt(value, 10);
                      if (intValue > 0) {
                        setPageSize(intValue);
                      }
                    } else if (value === "") {
                      setPageSize(""); // Allow clearing the input
                    }
                  }}
                  onBlur={(e) => {
                    // Set default page size if input is empty
                    if (e.target.value === "") {
                      setPageSize(20);
                    }
                  }}
                  pattern="\d+" // Allows only digits
                  title="Enter a positive number"
                  min={1}
                  className="pagesize-box"
                />

                <label htmlFor="pageSize">items per page</label>
              </div>
            </div>
          )}
        </div>
        <AppFooter />
      </>
    );
  }
};

export default AlertList;
