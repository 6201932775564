import React from 'react';
import { Spinner } from 'react-bootstrap';
import loader from '../../src/assets/loading-icon.png'
import { useSelector } from 'react-redux';

// const showLoader = () => (
//   const isLoading = useSelector((state) => state.loading.isLoading);
//   if (!isLoading) {
//     return null;
//   }
//   <div className="loader-container">
//     <div className="loader-content">
//       <Spinner animation="border" role="status">
//         <img src={loader} className="Spinner"/>
//       </Spinner>
//     </div>
//   </div>
// );


const Loader = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  if (!isLoading) return null;

  return (
    <div className="loader-container">
    <div className="loader-content">
      <Spinner animation="border" role="status">
        <img src={loader} className="Spinner"/>
      </Spinner>
    </div>
  </div>
  );
};

export default Loader;