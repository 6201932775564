import { configureStore } from '@reduxjs/toolkit';
import orgReducer from './orgSlice';
import loadingReducer from './loadingSlice';
import userInfoReducer from './userInfoSlice';
const store = configureStore({
  reducer: {
    loading: loadingReducer,
    org: orgReducer,
    user: userInfoReducer,
  },
});

export default store;