import React, { useState, useEffect } from "react";
import { Modal, Form, Button, ListGroup, Row, Col } from "react-bootstrap";
import { editUser } from "../../hooks/userEdit.js";
import { getUser } from "../../hooks/userInfo.js";
import { getRawTeamInfo } from "../../hooks/teamInfo.js";
import { Reminder } from "../../utils/validation.js";
import { MdVerified } from "react-icons/md";
import { FaPaperPlane } from "react-icons/fa";
import { resendOTP } from "../../hooks/resendOTP.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { RxCrossCircled } from "react-icons/rx";
import { showLoader, hideLoader } from "../../store/loadingSlice.js";
import Loader from "../../common/loader.js";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { RxCross2 } from "react-icons/rx";

const UserEditModal = ({
  show,
  onHide,
  userId,
  isEmailVerified,
  orgId,
  getOrgInfo,
  updateMessage,
}) => {
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([
    { orgId: null, teams: [] },
  ]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    whatsapp: "",
    assignedTeams: [],
    role: "",
    permissions: [],
    oldWhatsapp: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsapp: "",
    password: "",
    assignedTeams: "",
    role: "",
    permissions: "",
  });
  const [isWhatsappVerified, setIsWhatsappVerified] = useState(false);
  const [isWhatsappChanged, setIsWhatsappChanged] = useState(false);
  const [isVerificationLinkSent, setIsVerificationLinkSent] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [roles, setRoles] = useState(["Admin", "Normal User"]);
  const [allOrgs, setAllOrgs] = useState([]);
  const orgInfo = useSelector((state) => state.org.orgData);

  useEffect(() => {
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      setRoles(["Admin", "Tech Stack", "Engineer", "Project Analytics"]);
    }
  }, [orgInfo]);

  useEffect(() => {
    if (show) {
      dispatch(showLoader());
      getRawTeamInfo()
        .then((response) => {
          setTeamsInfo(response.data);
          dispatch(hideLoader());
        })
        .catch((error) => {
          updateMessage("error", `[${error.message}] Failed to get team info!`);
          dispatch(hideLoader());
        });
    }
  }, [show]);

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then((response) => {
          const user = response.data[0];
          setFormData({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            whatsapp: user.whatsapp,
            password: "",
            assignedTeams: user.team_ids,
            role: user.is_admin
              ? "Admin"
              : user.role === "user"
              ? "Normal User"
              : user.role
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
            permissions: [
              ...(user.can_edit ? ["Can Edit"] : []),
              ...(user.can_delete ? ["Can Delete"] : []),
              ...(user.can_add ? ["Can Add"] : []),
            ],
            oldWhatsapp: user.whatsapp,
          });
          setIsWhatsappVerified(user.is_whatsapp_verified);
          setIsVerificationLinkSent(false);
          setIsWhatsappChanged(false);
          const selectedTeams = [];
          if (
            orgInfo &&
            orgInfo.orgName === "Ensaras" &&
            Object.keys(user.org_vise_teams).length > 0
          ) {
            if (user.role !== "admin") {
              const getAllOrgs = teamsInfo
                ?.filter((org) => org.orgName !== "Ensaras")
                .map((org) => ({
                  value: org.orgId,
                  label: org.orgName,
                  teams: org.teams
                    ?.filter(
                      (team) => team.teamName !== "Master Data Management"
                    )
                    .map((team) => ({
                      value: team.teamId,
                      label: team.teamName,
                    })),
                }));
              setAllOrgs(getAllOrgs);
            }
            const selectedOrgs = Object.keys(user.org_vise_teams)
              .filter(
                (orgId) => user.org_vise_teams[orgId].org_name !== "Ensaras"
              )
              .map((orgId) => {
                const orgTeams = teamsInfo?.find(
                  (org) => org.orgId === Number(orgId)
                );
                return {
                  orgId: Number(orgId),
                  orgName: user.org_vise_teams[orgId].org_name,
                  teams: user.org_vise_teams[orgId].teams
                    .filter(
                      (team) => team.team_name !== "Master Data Management"
                    )
                    .map((team) => ({
                      value: team.team_id,
                      label: team.team_name,
                    })),
                  availableTeams: orgTeams
                    ? orgTeams.teams
                        .filter(
                          (team) => team.teamName !== "Master Data Management"
                        )
                        .map((team) => ({
                          value: team.teamId,
                          label: team.teamName,
                        }))
                    : [],
                };
              });

            if (selectedOrgs && selectedOrgs.length > 0) {
              console.log('selectedOrgs--->', selectedOrgs)
              setSelectedOrgs(selectedOrgs);
            }
            // if (user.role === "engineer" || user.role === "project_analytics") {
            //   const getAllOrgs = teamsInfo?.map((org) => ({
            //     value: org.orgId,
            //     label: org.orgName,
            //   }));
            //   setAllOrgs(getAllOrgs);
            // }
          } else {
            Object.values(user.org_vise_teams).forEach((org) => {
              org.teams.forEach((team) => {
                if (
                  user.team_ids.includes(team.team_id) &&
                  team.team_name !== "Master Data Management"
                ) {
                  selectedTeams.push({
                    teamId: team.team_id,
                    teamName: team.team_name,
                  });
                }
              });
            });
            setSelectedTeams(selectedTeams);
          }
        })
        .catch((error) => {
          console.log("eror--->", error);
          updateMessage("error", `[${error.message}] Failed to edit user!`);
        });
    }
  }, [userId, show, teamsInfo]);

  useEffect(() => {
    if (teamsInfo && orgId) {
      const currentOrg = teamsInfo.find(
        (item) => Number(item.orgId) === Number(orgId)
      );
      if (currentOrg) {
        setTeams(
          currentOrg.teams
            .filter((team) => team.teamName !== "Master Data Management")
            .map((team) => ({
              value: team.teamId,
              label: team.teamName,
            }))
        );
      }
    }
  }, [orgId, teamsInfo]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || e;
    const updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "role") {
      if (value === "Admin") {
        updatedFormData.permissions = ["Can Edit", "Can Delete", "Can Add"];
      } else {
        console.log("formData--->", formData);
        updatedFormData.permissions = [];
        setSelectedTeams([]);
        formData.assignedTeams = [];
      }
      if (value === "Engineer" || value === "Project Analytics") {
        console.log("getAllOrgs===>", getAllOrgs);

        const getAllOrgs = teamsInfo
          ?.filter((org) => org.orgName !== "Ensaras")
          .map((org) => ({
            value: org.orgId,
            label: org.orgName,
          }));
        setAllOrgs(getAllOrgs);
        setSelectedOrgs([{ orgId: null, teams: [] }]);
      } else if (value === "Tech Stack") {
        const getAllOrgs = teamsInfo
          ?.filter((org) => org.orgName === "Ensaras")
          .map((org) => ({
            value: org.orgId,
            label: org.orgName,
          }));
        setAllOrgs(getAllOrgs);
        setSelectedOrgs([{ orgId: null, teams: [] }]);
      }
    }

    setFormData(updatedFormData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleWhatsAppChange = (value) => {
    setErrors((prevErrors) => ({ ...prevErrors, whatsapp: "" }));
    setFormData((prevData) => ({
      ...prevData,
      whatsapp: value,
    }));
    if (value !== formData.oldWhatsapp) {
      setIsWhatsappChanged(true);
    } else {
      setIsWhatsappChanged(false);
    }
  };

  const handleSendVerificationLink = () => {
    const errorStatus = { ...errors };

    if (formData.whatsapp && !isValidPhoneNumber(formData.whatsapp)) {
      errorStatus.whatsapp = "Whatsapp number is invalid.";
    }

    if (Object.values(errorStatus).some((value) => value !== "")) {
      setErrors(errorStatus);
      return;
    }

    resendOTP({ user_id: userId })
      .then((response) => {
        updateMessage("success", "Verification link sent successfully!");
        setIsVerificationLinkSent(true);
      })
      .catch((error) => {
        updateMessage(
          "error",
          `[${error.message}] Failed to send verification link!`
        );
      });
  };

  const validateForm = () => {
    const {
      firstName = "",
      lastName = "",
      password = "",
      whatsapp = "",
      assignedTeams = [],
      role = "",
      permissions = [],
    } = formData;
    const newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    const nameRegex = /^[a-zA-Z'_ -]+$/;
    if (firstName && !nameRegex.test(firstName)) {
      newErrors.firstName =
        "First name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    if (lastName && !nameRegex.test(lastName)) {
      newErrors.lastName =
        "Last name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    // if (!email.trim()) {
    //   newErrors.email = "Email is required.";
    // }
    // if (email && !isEmail(email)) {
    //   newErrors.email = "Email is invalid.";
    // }
    if (
      password.trim() &&
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/
      )
    ) {
      if (password.trim() === "") {
        newErrors.password =
          "Password must have at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.";
      } else if (password.length < 8) {
        newErrors.password = "Password must have at least 8 characters.";
      } else if (!password.match(/[a-z]/)) {
        newErrors.password = "Password must have at least 1 lowercase letter.";
      } else if (!password.match(/[A-Z]/)) {
        newErrors.password = "Password must have at least 1 uppercase letter.";
      } else if (!password.match(/\d/)) {
        newErrors.password = "Password must have at least 1 number.";
      } else if (!password.match(/[a-zA-Z]/)) {
        newErrors.password = "Password must have at least 1 letter.";
      } else if (!password.match(/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]/)) {
        newErrors.password = "Password must have at least 1 special character.";
      }
    }

    if (whatsapp && !isValidPhoneNumber(whatsapp)) {
      newErrors.whatsapp = "Whatsapp number is invalid.";
    }

    if (!role) newErrors.role = "Role is required.";
    // if (role === "Normal User") {
    //   // if(permissions && permissions.length === 0)
    //   //   newErrors.permissions = "Please select at least one permission.";
    //   if (!assignedTeams.length)
    //     newErrors.assignedTeams = "Please select at least one team.";
    // }
    if (orgInfo && orgInfo.orgName !== "Ensaras" && role === "Normal User") {
      console.log("selected--->", selectedTeams);
      if (selectedTeams && selectedTeams.length === 0)
        newErrors.assignedTeams = "Please select at least one team.";
    }
    // if (orgInfo && orgInfo.orgName === "Ensaras") {
    //   if (role !== "Admin") {
    //     // if (
    //     //   !selectedOrgs ||
    //     //   selectedOrgs.length === 0 ||
    //     //   selectedOrgs.some((org) => !org.orgId)
    //     // ) {
    //     //   newErrors.selectedOrgs = "Please select at least one organization.";
    //     // }  else {
    //     selectedOrgs.forEach((org, index) => {
    //       if (!org.orgId) {
    //         newErrors[`selectedOrgs_${index}`] =
    //           "Please select at least one organization.";
    //       } else if (org.teams.length === 0) {
    //         newErrors[`assignedTeams_${index}`] =
    //           "Please select at least one team for this organization.";
    //       }
    //     });
    //     // }
    //   }
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    let userClone = {
      userId,
      orgId: Number(orgId),
      first_name: formData.firstName,
      last_name: formData.lastName,
      whatsapp: formData.whatsapp,
      password: formData.password,
      email: formData.email,
      is_admin: formData.role === "Admin" ? true : false,
      can_edit:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Edit"),
      can_delete:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Delete"),
      can_add:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Add"),
    };

    if (orgInfo && orgInfo.orgName === "Ensaras") {
      userClone = {
        ...userClone,
        role:
          formData.role === "Admin"
            ? "admin"
            : formData.role.toLowerCase().replace(/\s+/g, "_"),
        teams: selectedOrgs.flatMap((org) =>
          org.teams.map((team) => team.value)
        ),
      };
      // if (
      //   formData.role === "Engineer" ||
      //   formData.role === "Project Analytics" ||
      //   formData.role === "Tech Stack"
      // ) {
      //   const ensarasOrg = teamsInfo.find((org) => org.orgName === "Ensaras");
      //   if (ensarasOrg) {
      //     const ensarasTeam = ensarasOrg.teams.find(
      //       (team) => team.teamName === formData.role
      //     );
      //     if (ensarasTeam) {
      //       userClone.teams.push(ensarasTeam.teamId);
      //       userClone.teams = userClone.teams.filter((teamId) => {
      //         const team = teamsInfo
      //         .find((org) => org.orgName === "Ensaras")
      //         ?.teams.find((team) => team.teamId === teamId);
      //         return !team;
      //       });
      //       if(formData.role === "Tech Stack"){
      //         userClone.teams = userClone.teams.filter((teamId) => {
      //           const team = teamsInfo
      //             .find((org) => org.orgName === "Ensaras")
      //             ?.teams.find((team) => team.teamId === teamId);
      //           return team && team.teamName === "Tech Stack";
      //         });
      //       }

      //     }
      //   }
      // }
      if (
        formData.role === "Engineer" ||
        formData.role === "Project Analytics" ||
        formData.role === "Tech Stack"
      ) {
        const ensarasOrg = teamsInfo.find((org) => org.orgName === "Ensaras");

        if (ensarasOrg) {
          // Find the team in Ensaras corresponding to the role
          const selectedTeam = ensarasOrg.teams.find(
            (team) => team.teamName === formData.role
          );

          if (selectedTeam) {
            // Add the selected team's teamId to userClone.teams
            userClone.teams.push(selectedTeam.teamId);

            // Filter teams based on the role
            userClone.teams = userClone.teams.filter((teamId) => {
              const team = ensarasOrg.teams.find(
                (team) => team.teamId === teamId
              );

              if (formData.role === "Tech Stack") {
                // Only keep Tech Stack teams for this role
                return team && team.teamName === "Tech Stack";
              } else if (
                formData.role === "Engineer" ||
                formData.role === "Project Analytics"
              ) {
                // Keep teams from other organizations or the relevant Ensaras team
                return !team || team.teamName === formData.role;
              }
              return true; // Retain other teams as fallback
            });
          }
        }
      }
    } else {
      userClone = {
        ...userClone,
        teams: formData.assignedTeams,
        role: formData.role === "Admin" ? "admin" : "user",
      };
      if (formData.role === "Admin") {
        userClone.teams =
          teamsInfo
            .find((org) => Number(org.orgId) === Number(orgId))
            ?.teams.map((team) => team.teamId) || [];
      }
    }
    dispatch(showLoader());
    editUser(userClone)
      .then((response) => {
        handleModalHide();
        updateMessage("success", response.message);
        getOrgInfo();
        dispatch(hideLoader());
      })
      .catch((error) => {
        updateMessage("error", `${error.message}`);
        dispatch(hideLoader());
      });
  };

  const handleModalHide = () => {
    setFormData({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      whatsapp: "",
      assignedTeams: [],
      role: "",
      permissions: [],
      oldWhatsapp: "",
    });
    setSelectedTeams([]);
    setErrors({});
    setSelectedOrgs([{ orgId: null, teams: [] }]); // Reset selectedOrgs
    setIsWhatsappVerified(false);
    setIsVerificationLinkSent(false);
    setIsWhatsappChanged(false);
    onHide();
  };

  const handleTeamChange = (index, selectedOption) => {
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      const updatedOrgs = [...selectedOrgs];
      const teamId = selectedOption.value;
      if (!updatedOrgs[index].teams) {
        updatedOrgs[index].teams = [];
      }
      if (!updatedOrgs[index].teams.some((t) => t.value === teamId)) {
        updatedOrgs[index].teams.push(selectedOption);
      }
      setSelectedOrgs(updatedOrgs);
      // setErrors((prevErrors) => {
      //   const newErrors = { ...prevErrors };
      //   delete newErrors[`assignedTeams_${index}`];
      //   return newErrors;
      // });
    } else {
      const teamId = selectedOption.value;
      if (!selectedTeams.some((t) => t.teamId === teamId)) {
        const updatedSelectedTeams = [
          ...selectedTeams,
          { teamId, teamName: selectedOption.label },
        ];
        setSelectedTeams(updatedSelectedTeams);
        setFormData((prevData) => ({
          ...prevData,
          assignedTeams: updatedSelectedTeams.map((team) => team.teamId),
        }));
      }
      if (errors.assignedTeams) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.assignedTeams;
          return newErrors;
        });
      }
    }
  };

  const handleRemoveSelectedTeam = (orgIndex, teamId) => {
    console.log(orgIndex, teamId);
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      const updatedOrgs = [...selectedOrgs];
      updatedOrgs[orgIndex].teams = updatedOrgs[orgIndex].teams.filter(
        (team) => Number(team.value) !== Number(teamId)
      );
      setSelectedOrgs(updatedOrgs);
    } else {
      const updatedTeams = selectedTeams.filter(
        (team) => team.teamId !== teamId
      );
      setSelectedTeams(updatedTeams);
      setFormData((prevData) => ({
        ...prevData,
        assignedTeams: updatedTeams.map((team) => team.teamId),
      }));
    }
  };

  const handleOrgChange = (index, selectedOption) => {
    console.log("selectedOption", selectedOption);
    const updatedOrgs = [...selectedOrgs];
    if (selectedOption) {
      updatedOrgs[index].orgId = selectedOption.value;
      const orgTeams = teamsInfo?.find(
        (org) => org.orgId === selectedOption.value
      );
      if (orgTeams) {
        updatedOrgs[index].availableTeams = orgTeams.teams
          .filter((team) => team.teamName !== "Master Data Management")
          .map((team) => ({
            value: team.teamId,
            label: team.teamName,
          }));
        // if (selectedOption && selectedOption.label === "Ensaras") {
        //   updatedOrgs[index].availableTeams = orgTeams.teams
        //     .filter(
        //       (team) =>
        //         team.teamName !== "Tech Stack" ||
        //         team.teamName !== "Engineer" ||
        //         team.teamName !== "Project Analytics"
        //     )
        //     .map((team) => ({
        //       value: team.teamId,
        //       label: team.teamName,
        //     }));
        // }
        updatedOrgs[index].teams = []; // Clear selected teams
      }
    } else {
      updatedOrgs[index].orgId = null;
      updatedOrgs[index].availableTeams = [];
      updatedOrgs[index].teams = []; // Clear selected teams
    }
    setSelectedOrgs(updatedOrgs);
    // setErrors((prevErrors) => {
    //   const newErrors = { ...prevErrors };
    //   delete newErrors[`selectedOrgs_${index}`];
    //   delete newErrors[`assignedTeams_${index}`];
    //   return newErrors;
    // });
  };

  const handleRemoveOrg = (index) => {
    const updatedOrgs = selectedOrgs.filter((_, i) => i !== index);
    setSelectedOrgs(updatedOrgs);
    // setErrors((prevErrors) => {
    //   const newErrors = { ...prevErrors };
    //   delete newErrors[`selectedOrgs_${index}`];
    //   delete newErrors[`assignedTeams_${index}`];
    //   return newErrors;
    // });
  };

  const handleAddAnotherOrg = () => {
    setSelectedOrgs([...selectedOrgs, { orgId: null, teams: [] }]);
  };

  return (
    <Modal className="edit-popup" show={show} onHide={handleModalHide} centered>
      <Loader />
      <Modal.Header closeButton>
        <Modal.Title>Edit User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="modal-fld">
            <Form.Label>
              First Name <span className="text-danger">*</span>
            </Form.Label>

            <div className="txx-fld">
              <Form.Control
                name="firstName"
                isInvalid={!!errors.firstName}
                required
                type="text"
                placeholder="Enter First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="modal-fld">
            <Form.Label>
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <div className="txx-fld">
              <Form.Control
                name="lastName"
                isInvalid={!!errors.lastName}
                required
                type="text"
                placeholder="Enter Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="modal-fld">
            <Form.Label>
              Email Address <span className="text-danger">*</span>
            </Form.Label>
            <div
              className="txx-fld"
              style={{ background: "rgb(231 236 237 / 26%)" }}
            >
              <div className="input-with-icon">
                {/* <Form.Control
                  name="email"
                  type="email"
                  isInvalid={!!errors.email}
                  required
                  placeholder="Enter Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                /> */}

                <em>{formData.email}</em>
                <div className="verification-status">
                  {isEmailVerified ? (
                    <span>
                      (verified){" "}
                      <FaCircleCheck className="input-icon verified" />
                    </span>
                  ) : (
                    <span>
                      (pending verification )
                      <FaCircleCheck className="input-icon pending-verification" />
                    </span>
                  )}
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="modal-fld tp-align">
            <Form.Label>Password</Form.Label>
            <div className="txx-fld">
              <Form.Control
                name="password"
                isInvalid={!!errors.password}
                required
                type="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleChange}
              />
              {!errors.password && (
                <Form.Text className="text-muted">
                  {Reminder("password")}
                </Form.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          {isEmailVerified && (
            <Form.Group className="modal-fld">
              <Form.Label>WhatsApp Number </Form.Label>
              <div className="txx-fld">
                <div className="phone-input-container">
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={formData.whatsapp}
                    onChange={handleWhatsAppChange}
                    placeholder="Enter WhatsApp Number"
                    className={`form-control ${
                      !!errors.whatsapp ? "is-invalid" : ""
                    }`}
                  />
                  {!errors.whatsapp && formData.whatsapp && (
                    <div
                      className={`validation-text ${
                        isWhatsappVerified ? "verified" : "pending"
                      }`}
                    >
                      <span>
                        {!isWhatsappChanged &&
                          (isWhatsappVerified
                            ? "(verified)"
                            : "(pending verification)")}
                      </span>
                      {!isWhatsappChanged &&
                        (isWhatsappVerified ? (
                          <FaCircleCheck className="input-icon verified" />
                        ) : (
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "blue",
                              cursor: "pointer",
                              fontSize: "16px",
                            }}
                            type="button"
                            onClick={handleSendVerificationLink}
                            disabled={isVerificationLinkSent}
                            title="Send Verification Link"
                          >
                            <FaPaperPlane
                              style={{
                                fontSize: "18px",
                                color: isVerificationLinkSent ? "gray" : "blue",
                              }}
                            />
                          </button>
                        ))}
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.whatsapp}
                  </Form.Control.Feedback>
                </div>
              </div>
            </Form.Group>
          )}

          <Form.Group
            controlId="roles"
            className="align-items-center modal-fld"
          >
            <Form.Label>
              Role <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <div className="role-gtp">
                {roles.map((role) => (
                  <label
                    key={role}
                    className={`custom-radio ${errors.role ? "invalid" : ""}`}
                  >
                    <span>{role}</span>
                    <Form.Check
                      className="check-inner"
                      type="radio"
                      name="role"
                      value={role}
                      checked={formData.role === role}
                      onChange={handleChange}
                      isInvalid={!!errors.role}
                    />
                  </label>
                ))}
              </div>
              {errors.role && (
                <div className="invalid-feedback d-block">{errors.role}</div>
              )}
            </Col>
          </Form.Group>

          {orgInfo &&
            orgInfo.orgName === "Ensaras" &&
            formData.role &&
            formData.role !== "Admin" &&
            formData.role !== "Tech Stack" && (
              <>
                {selectedOrgs?.map((org, index) => (
                  <div
                    key={index}
                    className={`${index !== 0 ? "another-org" : ""}`}
                  >
                    {index !== 0 && (
                      <span
                        className="cross-icon"
                        onClick={() => handleRemoveOrg(index)}
                      >
                        <RxCross2 />
                      </span>
                    )}
                    <Form.Group
                      controlId={`orgs-${index}`}
                      className="modal-fld tp-align"
                    >
                      {index === 0 && (
                        <Form.Label>
                          Assigned Organizations{" "}
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                      )}

                      {index !== 0 && <label></label>}

                      <div className="txx-fld">
                        <Col>
                          <Select
                            name={`orgs-${index}`}
                            value={
                              allOrgs.find(
                                (o) => Number(o.value) === Number(org.orgId)
                              ) || null
                            }
                            onChange={(option) =>
                              handleOrgChange(index, option)
                            }
                            options={allOrgs}
                            placeholder="Select Orgs"
                            isInvalid={!!errors[`selectedOrgs_${index}`]}
                            isOptionDisabled={(option) =>
                              selectedOrgs?.some(
                                (o) =>
                                  o.orgId === option.value &&
                                  o.orgId !== org.orgId
                              )
                            }
                            classNamePrefix={
                              !!errors[`selectedOrgs_${index}`]
                                ? "invalid"
                                : "select-dropdown"
                            }
                            menuPlacement="auto"
                            isClearable
                          />
                          {errors[`selectedOrgs_${index}`] && (
                            <div className="invalid-feedback d-block">
                              {errors[`selectedOrgs_${index}`]}
                            </div>
                          )}
                        </Col>
                      </div>
                    </Form.Group>

                    <div className="org-teams">
                      <Form.Group
                        controlId="assignedTeams"
                        className="modal-fld tp-align"
                      >
                        <label> </label>
                        <div className="txx-fld">
                          <Col>
                            <Select
                              name="teams"
                              value={null}
                              onChange={(option) =>
                                handleTeamChange(index, option)
                              } // Use index 0 for non-Ensaras
                              options={org.availableTeams || []}
                              placeholder="Select Teams"
                              isInvalid={!!errors[`assignedTeams_${index}`]}
                              isOptionDisabled={(option) =>
                                org.teams.some((f) => f.value === option.value)
                              }
                              classNamePrefix={
                                !!errors[`assignedTeams_${index}`]
                                  ? "invalid"
                                  : "select-dropdown"
                              }
                              menuPlacement="auto"
                              isClearable
                            />
                            {errors[`assignedTeams_${index}`] && (
                              <div className="invalid-feedback d-block">
                                {errors[`assignedTeams_${index}`]}
                              </div>
                            )}
                            <div className="assigned-teams-box mt-3">
                              <ListGroup>
                                <ListGroup.Item className="assigned-teams-label">
                                  <div className="team-title">
                                    Assigned Teams
                                  </div>
                                  <div className="team-items-name">
                                    {org.teams.map((team, teamIndex) => (
                                      <ListGroup.Item
                                        key={teamIndex}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <span className="team-name-title">
                                          {team.label}
                                        </span>

                                        <Button
                                          variant="link"
                                          className="text-danger"
                                          onClick={() =>
                                            handleRemoveSelectedTeam(
                                              index,
                                              team.value
                                            )
                                          }
                                        >
                                          <RxCrossCircled />
                                        </Button>
                                      </ListGroup.Item>
                                    ))}
                                  </div>
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                          </Col>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                ))}
                {formData.role !== "Tech Stack" && (
                  <Button
                    variant="link"
                    onClick={handleAddAnotherOrg}
                    disabled={
                      !selectedOrgs[selectedOrgs.length - 1].orgId ||
                      selectedOrgs[selectedOrgs.length - 1].teams.length === 0
                    }
                  >
                    + Add another Organization
                  </Button>
                )}
              </>
            )}

          {orgInfo &&
            orgInfo.orgName !== "Ensaras" &&
            formData.role === "Normal User" && (
              <>
                <Form.Group
                  controlId="assignedTeams"
                  className="modal-fld tp-align"
                >
                  <Form.Label>
                    Assigned Teams <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="txx-fld">
                    <Col>
                      <Select
                        name="teams"
                        value=""
                        onChange={(option) => handleTeamChange(0, option)} // Use index 0 for non-Ensaras
                        options={teams}
                        placeholder="Select Teams"
                        isInvalid={!!errors.assignedTeams}
                        isOptionDisabled={(option) =>
                          selectedTeams.some((f) => f.teamId === option.value)
                        }
                        classNamePrefix={
                          !!errors.forms ? "invalid" : "select-dropdown"
                        }
                        menuPlacement="auto"
                        isClearable
                      />
                      {errors.assignedTeams && (
                        <div className="invalid-feedback d-block">
                          {errors.assignedTeams}
                        </div>
                      )}
                      <div className="assigned-teams-box mt-3">
                        <ListGroup>
                          <ListGroup.Item className="assigned-teams-label">
                            <div className="team-title">Assigned Teams</div>
                            <div className="team-items-name">
                              {selectedTeams.map((team, index) => (
                                <ListGroup.Item
                                  key={index}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <span className="team-name-title">
                                    {team.teamName}
                                  </span>

                                  <Button
                                    variant="link"
                                    className="text-danger"
                                    onClick={() =>
                                      handleRemoveSelectedTeam(0, team.teamId)
                                    }
                                  >
                                    <RxCrossCircled />
                                  </Button>
                                </ListGroup.Item>
                              ))}
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </Col>
                  </div>
                </Form.Group>
              </>
            )}

          {formData.role !== "Ensaras User" && (
            <Form.Group controlId="permissions" className="tp-align modal-fld">
              <Form.Label>Permissions</Form.Label>
              <Col>
                <div className="permissions-gtp">
                  {["Can Add", "Can Edit", "Can Delete"].map((permission) => (
                    <label
                      key={permission}
                      className={`custom-checkbox ${
                        errors.permissions ? "invalid" : ""
                      }`}
                    >
                      <span>{permission}</span>
                      <Form.Check
                        className="check-inner"
                        name="permissions"
                        value={permission}
                        checked={formData.permissions.includes(permission)}
                        onChange={(e) => {
                          if (formData.role !== "Admin") {
                            const isChecked = e.target.checked;
                            setFormData((prevData) => ({
                              ...prevData,
                              permissions: isChecked
                                ? [...prevData.permissions, permission]
                                : prevData.permissions.filter(
                                    (p) => p !== permission
                                  ),
                            }));
                          }
                        }}
                      />
                    </label>
                  ))}
                </div>
                {/* {errors.permissions && (
                  <div className="invalid-feedback d-block">
                    {errors.permissions}
                  </div>
                )} */}
              </Col>
            </Form.Group>
          )}
        </Form>

        <Button variant="primary" className="save-btn" onClick={handleSubmit}>
          Edit
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UserEditModal;
