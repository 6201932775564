import { createSlice } from '@reduxjs/toolkit';

const orgSlice = createSlice({
  name: 'org',
  initialState: {
    orgData: null,
  },
  reducers: {
    setOrgData: (state, action) => {
      state.orgData = action.payload;
    },
  },
});

export const { setOrgData } = orgSlice.actions;
export default orgSlice.reducer;